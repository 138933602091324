<template>
  <!-- 作业考试 -->
  <div class="main-contain">
    <div class="contain">
      <div class="rediobt">
        <el-radio-group v-model="state" @change="changeState">
          <el-radio :label="0">未提交</el-radio>
          <el-radio :label="1">已提交</el-radio>
        </el-radio-group>
      </div>
      <el-table
        ref="singleTable"
        :data="tableData"
        highlight-current-row
        :show-header="false"
        class="Table"
        style="width: 100%"
      >
        <el-table-column min-width="280">
          <template slot-scope="scope">
            <div class="item-left">
              <el-image class="elimage" :src="testIcon" />
              <div class="text">
                <div class="title">{{ scope.row.name }}</div>
                <div class="time-contain">
                  <div class="time">{{ scope.row.end_time | dataformat }}</div>
                  <div class="time">截止</div>
                  <div class="bt">{{ scope.row.type | typeformat }}</div>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <div class="item-middle">
              <div class="title">题目数量</div>
              <div class="num">{{ scope.row.total_stem_count }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <div class="item-middle">
              <div class="title">总分</div>
              <div class="num">{{ scope.row.total_score }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column v-if="state">
          <template slot-scope="scope">
            <div class="item-middle">
              <div class="title">分数</div>
              <div class="num" v-if="scope.row.review_status == 1">
                {{ scope.row.user_score }}
              </div>
              <div class="num" v-else>未批阅</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <div class="item-middle">
              <div class="title">关联章节</div>
              <div class="look" @click="lookbt(scope.row.test_id)">查看</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <div class="item-right">
              <template v-if="state">
                <div v-if="scope.row.review_status == 1">
                  <div class="bt empty" @click="toDetail(scope.row)">详情</div>
                </div>
                <div v-else>
                  <div
                    class="bt"
                    v-if="scope.row.test_period_user_status == 2"
                    @click="toAnswer(scope.row)"
                  >
                    被驳回
                  </div>
                  <div class="bt empty" v-else @click="toDetail(scope.row)">
                    详情
                  </div>
                </div>
              </template>
              <template v-else>
                <template v-if="scope.row.access_status">
                  <div class="bt" @click="toAnswer(scope.row)">答题</div>
                </template>
                <template v-else>无权限</template>
              </template>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <!-- <pag-table
        class="paginationBox"
        :total="total"
        :page="page"
        :limit="per_page"
        @changeSize="changeSize"
        @changePage="changePage"
        style="margin-top: 10px; text-align: center"
      />-->
    </div>
    <!-- 关联章节 -->
    <Customdialog
      class="customdialog"
      ref="dialog_catalogue"
      type="max"
      title="关联章节"
      width="800px"
      :showclose="true"
    >
      <div slot="dialogbody" class="dialogbody">
        <Table ref="chapters"></Table>
      </div>
      <div slot="dialogfooter" class="dialogfooter"></div>
    </Customdialog>
    <!-- 试卷详情 -->
    <Customdialog
      class="customdialog"
      ref="dialog_testdetail"
      type="min"
      :title="testTitle"
      width="50%"
      :showclose="true"
    >
      <div slot="dialogbody" class="dialogbody">
        <div class="my_test">
          <div class="remark" v-if="isRightness">
            老师评语：{{ testInfo.user_remark }}
          </div>
          <div v-for="(item, index) in testStemList" :key="index">
            <div class="topic_type">
              <div class="order">{{ AutoNumberByIndex(item.type - 1) }}</div>
              <div class="classify">{{ item.type | stemformat }}</div>
              <div class="score">
                （{{ item.child.length }}题，共{{ item.score }}分）
              </div>
            </div>
            <div class="subject" v-for="(stem, si) in item.child" :key="si">
              <div class="question">
                <div class="order">{{ si + 1 }},</div>
                <div class="score">({{ stem.score }}分)</div>
                <div class="title" v-html="stem.title"></div>
              </div>
              <template v-if="item.type < 3">
                <div
                  class="option"
                  v-for="(option, oi) in stem.option"
                  :key="oi"
                  :style="item.type == 3 ? 'margin-bottom:10px' : ''"
                >
                  <div
                    :class="
                      option.is_check
                        ? 'order qs_right qs_active'
                        : 'order qs_right'
                    "
                  >
                    {{ AutoNumberByLetter(oi) }}
                  </div>
                  <div class="content" v-html="option.content"></div>
                </div>
              </template>
              <template v-if="stem.user_answer && stem.user_answer.answer">
                <div class="user_answer">
                  <div class="imgAnalysis" v-if="isRightness">
                    <img
                      v-if="stem.user_answer.is_right"
                      src="@/assets/right.png"
                    />
                    <img v-else src="@/assets/wrong.png" />
                  </div>
                  <div class="content">
                    <div class="user_section">
                      <div class="section_title">学生答案：</div>
                      <div class="answer" v-if="stem.user_answer.answer">
                        <template v-if="stem.user_answer.answer.length === 0">
                          <span style="color: red">[未答题]</span>
                        </template>
                        <template v-else>
                          <div
                            class="answer_item"
                            v-for="(answer, ai) in stem.user_answer.answer"
                            :key="ai"
                          >
                            <template v-if="item.type < 4">
                              <div
                                v-for="(option, oi) in stem.option"
                                :key="oi"
                              >
                                <div
                                  v-if="answer && answer == option.id"
                                  style="min-width: 30px; margin: 0 5px"
                                >
                                  <span v-if="item.type == 3">{{
                                    oi == 0 ? "正确" : "错误"
                                  }}</span>
                                  <span v-else>{{
                                    AutoNumberByLetter(oi)
                                  }}</span>
                                </div>
                              </div>
                            </template>
                            <template v-if="item.type == 4">
                              <div
                                v-if="answer"
                                style="
                                  display: flex;
                                  min-width: 30px;
                                  margin: 0 5px;
                                "
                              >
                                <span>{{ ai + 1 }}、</span>
                                <span v-html="answer"></span>
                              </div>
                            </template>
                            <template v-if="item.type > 4">
                              <div
                                v-if="answer"
                                v-html="answer"
                                style="min-width: 30px; margin: 0 5px"
                              ></div>
                            </template>
                          </div>
                        </template>
                      </div>
                      <div
                        class="score"
                        v-if="isRightness"
                        style="margin-left: 10px"
                      >
                        得分：{{
                          stem.user_answer.score ? stem.user_answer.score : 0
                        }}
                      </div>
                    </div>
                    <div
                      class="user_section"
                      v-if="stem.user_answer.file_list.length > 0"
                    >
                      <div class="section_title">附件：</div>
                      <div class="file_list">
                        <div
                          class="file"
                          v-for="(file, fo) in stem.user_answer.file_list"
                          :key="fo"
                          @click="appendix(file.url)"
                        >
                          {{ file.name }}
                        </div>
                      </div>
                    </div>
                    <div class="remark" v-if="isRightness">
                      评语：{{
                        stem.user_answer.remark ? stem.user_answer.remark : ""
                      }}
                    </div>
                    <div class="user_section" v-if="isRightness">
                      <div class="section_title">标准答案：</div>
                      <div class="rightness">
                        <div
                          class="answer_item"
                          v-for="(option, oi) in stem.option"
                          :key="oi"
                        >
                          <div v-if="option.is_right">
                            <span v-if="item.type < 3">{{
                              AutoNumberByLetter(oi)
                            }}</span>
                            <span v-else-if="item.type == 3">{{
                              oi == 0 ? "正确" : "错误"
                            }}</span>
                            <span
                              v-else-if="item.type == 4"
                              style="display: flex; margin-right: 5px"
                            >
                              <span>{{ oi + 1 }}、</span>
                              <span v-html="option.content"></span>
                            </span>
                            <span v-else v-html="option.content"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div slot="dialogfooter" class="dialogfooter"></div>
    </Customdialog>
  </div>
</template>
<script>
import dayjs from "dayjs";
import Table from "./table";
import Customdialog from "@/components/customdialog.vue";
import pagTable from "@/components/Pagination/pagTable";
import { gettestperiodList } from "@/api/testperiod";
import { gettestperioduserInfo } from "@/api/testperioduser";
import { Base64 } from "js-base64";

const request_base_path = require("@/utils/base_url");
// 试卷类型
const typeMap = ["其他", "作业", "考试"];
// 题目类型：0：其他，1：单选，2：多选，3：判断，4：填空，5：简答
const stemMap = {
  0: "其他题",
  1: "单选题",
  2: "多选题",
  3: "判断题",
  4: "填空题",
  5: "简答题",
};

export default {
  name: "virtualexperiment",
  components: {
    Customdialog,
    Table,
    pagTable,
  },

  data() {
    return {
      courseKey: "", // 课程编号
      tableData: [], // 试卷列表
      per_page: 100, // 每页条数
      page: 1,
      total: 0,
      state: 0, //0 未提交 1已提交
      testIcon: require("@/assets/coursedetails/组 10.png"),
      testTitle: "", // 查看试卷的名称
      testInfo: {},
      testStemList: [], // 试卷试题库
      isRightness: false, // 是否有正解
      filePreview: "", // 数据预览
      picturesPreview: "", // 多图预览
      fileTask: "", // 数据预埋
    };
  },
  filters: {
    dataformat(value) {
      if (!value) return "";
      return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss");
    },
    typeformat(val) {
      return typeMap[val];
    },
    stemformat(status) {
      return stemMap[status];
    },
  },
  // watch: {
  //   resStemList: {
  //     handler(newV) {
  //       if (!newV.length) {
  //         return false;
  //       }
  //       this.stemList = this.AutoNumberByLetter(newV);
  //     },
  //     immediate: true,
  //     deep: true,
  //   },
  // },
  created() {
    const systemConfig = sessionStorage.getItem("systemConfig");
    const systemInfo = systemConfig ? JSON.parse(systemConfig) : {};
    if (systemInfo.file_preview_url) {
      this.filePreview = systemInfo.file_preview_url + "/onlinePreview?url="; // 数据预览
      this.picturesPreview = systemInfo.file_preview_url + "/picturesPreview?urls="; // 多图预览
      this.fileTask = systemInfo.file_preview_url + "/addTask?url=" // 数据预埋
    } else {
      this.filePreview = request_base_path.pro_url + "/file-preview/onlinePreview?url=" // 数据预览
      this.picturesPreview = request_base_path.pro_url + "/file-preview/picturesPreview?urls=" // 多图预览
      this.fileTask = request_base_path.pro_url + "/file-preview/addTask?url=" // 数据预埋
    }
  },
  methods: {
    // 进入页面加载口
    initdata(obj, courseKey) {
      console.log("作业考试", obj, courseKey);
      if (courseKey) {
        this.courseKey = courseKey;
        this.gettestperiodList();
      }
    },
    // 获取作业考试列表
    gettestperiodList() {
      this.tableData = [];
      let params = {
        course_semester_sn: this.courseKey, // 课程编号
        id_type: 1,
        per_page: this.per_page, // 每页条数
        page: this.page, // 当前页数
      };
      if (this.state) {
        params.test_period_user_status_is_submit = 1; // 获取已提交的 (包含作业状态为已提交 和 驳回的)
      } else {
        params.test_period_user_status = 0; //用户试卷状态，0：未提交，1：已提交
      }
      gettestperiodList(params)
        .then((res) => {
          console.log(res);
          if (res.code === 0 && res.data) {
            // 过滤无权限数据
            this.tableData = res.data.data.filter((item) => {
              return item.access_status === 1;
            });
            this.total = res.data.total;
          } else {
            console.log("试卷列表：", res.message);
          }
        })
        .catch((error) => {
          console.log("试卷列表：", error);
        });
    },
    // 切换单页条数
    changeSize(data) {
      this.per_page = parseInt(data);
      this.gettestperiodList();
    },
    // 切换当前页数
    changePage(data) {
      this.page = parseInt(data);
      this.gettestperiodList();
    },
    // 赛选查询
    changeState() {
      this.gettestperiodList();
    },

    // 查看章节
    lookbt(id) {
      this.$refs.dialog_catalogue.dialogopenbt();
      this.$nextTick(() => {
        this.$refs.chapters.initdata(this.courseKey, id);
      });
    },
    // 回答
    toAnswer(row) {
      let params = { test_period_id: row.test_period_id };
      gettestperioduserInfo(params).then((res) => {
        if (res.code === 0) {
          let date = new Date();
          let now = parseInt(date.getTime() / 1000); // 当前时分秒
          //console.log("当前状态：" + row.status)
          //console.log("现在时间：" + now + "小于考试时间：" + row.start_time + "时不允许进入答题")
          let start = dayjs.unix(row.start_time).format("YYYY-MM-DD HH:mm:ss");
          // 判断考试状态
          if (row.status == 1) {
            if (now < row.start_time) {
              this.$message.warning("考试开始时间为: " + start);
            } else {
              this.$router.replace({
                path: "/home/wisdompracticeteaching/answer",
                query: {
                  test_period_id: row.test_period_id,
                  course_semester_sn: this.courseKey,
                  page_type: 0,
                },
              });
            }
          } else if (row.status == 2) {
            this.$message.warning("已结束,无法答题!");
          } else {
            this.$message.warning("考试开始时间为: " + start);
          }
        }
      });
    },
    // 详情
    toDetail(row) {
      this.isRightness = false; // 起始关闭
      this.testTitle = row.test_name; // 获取当前试卷名
      let params = { test_period_id: row.test_period_id };
      gettestperioduserInfo(params).then((res) => {
        if (res.code === 0) {
          this.testInfo = JSON.parse(JSON.stringify(res.data));
          delete this.testInfo.stem_list;
          // 格式化数据
          let stemList = res.data.stem_list.map((item) => {
            // 问题
            item.title = this.AutoCorrection(item.title);
            // 答案
            if (item.option && item.option.length > 0) {
              for (let i = 0; i < item.option.length; i++) {
                let str = item.option[i].content;
                item.option[i].content = this.AutoCorrection(str);
                if (item.option[i].is_right) {
                  this.isRightness = true;
                }
              }
            }
            // 学生答案
            if (item.user_answer.answer) {
              if (item.user_answer.answer.length >= 0) {
                for (let i = 0; i < item.user_answer.answer.length; i++) {
                  let str = item.user_answer.answer[i];
                  item.user_answer.answer[i] = this.AutoCorrection(str);
                }
              } else {
                let keylist = Object.keys(item.user_answer.answer); // 获取对象全部key值
                for (let i = 0; i < keylist.length; i++) {
                  let key = keylist[i];
                  let str = item.user_answer.answer[key];
                  item.user_answer.answer[key] = this.AutoCorrection(str);
                }
              }
            }
            return item;
          });

          this.testStemList = []; // 清空上一个试卷数据
          // 更改数组结构
          if (stemList && stemList.length > 0) {
            this.formattingData(this.testStemList, stemList); // 生成新数组
            // type为属性名-题目类型
            this.testStemList.sort(this.compare("type")); // 按type字段重新排序
          }
          this.$refs.dialog_testdetail.dialogopenbt(); // 打开试卷查看窗口
        }
      });
    },
    // 处理数组-把一个数组拆分为多个数组
    formattingData(arr, data) {
      // 新数组、原数组
      data.forEach((item) => {
        item.active = false; // 设置默认无点击状态
        const parent = arr.find((cur) => cur.type === item.type);
        if (parent) {
          parent.child.push(item);
          // 计算分数
          parent.score += item.score;
        } else {
          const obj = {
            type: item.type,
            score: item.score,
            child: [item],
          };
          arr.push(obj);
        }
      });
    },
    // 数值排序
    compare(property) {
      return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        return value1 - value2;
      };
    },
    // 循环修正数据与数据格式
    AutoCorrection(str) {
      const that = this;
      let newStr = str.replaceAll(/&nbsp;/gi, ""); //去除空格字符
      newStr = newStr.replace(/[\r\n]/g, "").trim(); // 去与换行与前后空格
      newStr = newStr.replace(/\\/g, ""); // 去除反斜杠
      newStr = newStr.replace(/\s+/g, " "); // 去除多余空格
      // newStr = newStr.replace(/<p>(\s|\&nbsp\;| |　|\xc2\xa0)*<\/p>/ig, ""); // 去掉多余 P 标签
      // 判断是否有图片，如果有图片
      let isHavePic = newStr.includes("<img");
      if (isHavePic) {
        // 正则过滤出图片
        newStr.replace(/<img.+?>/gi, function (matchImg, captureImg) {
          // console.log("正则匹配所有图片并获取相应下标==>",matchImg, captureImg);
          // newStr = newStr.replace(match,""); // 清除图片
          let resImgSrc = ""; // 自定义变量接收图片
          matchImg.replace(
            /<img[^>]*src=['"]([^'"]+)[^>]*>/gi,
            function (matchSrc, captureSrc) {
              // console.log("正则匹配该图片并获取相应路径==>",matchSrc, captureSrc);
              resImgSrc = captureSrc.replace(
                "../uploads",
                that.$api.baseUrl + "/uploads"
              );
            }
          );
          // 在图片中插入点击事件并默认不展示
          let newImgStr = matchImg.replace(
            /<s?img[^>]*src=['"]([^'"]+)/gi,
            `<img src="${resImgSrc}" style="max-width:80%; margin: 4px;"`
          );
          newStr = newStr.replace(matchImg, newImgStr);
        });
      }
      // 判断是否有视频，如果有视频
      let isHaveMove = newStr.includes("<video");
      if (isHaveMove) {
        // 正则过滤出视频
        newStr.replace(
          /(<video[\s\S]*?>)[\s\S]*?(<\/video>)/gi,
          function (matchVideo, captureVideo) {
            // console.log("正则匹配所有视频并获取相应下标==>",matchVideo, captureVideo);
            // newStr = newStr.replace(matchVideo,""); // 清空视频
            let resMoveurl = ""; // 自定义变量接收视频
            matchVideo.replace(
              /<source [^>]*src=['"]([^'"]+)[^>]*>/gi,
              function (matchUrl, captureUrl) {
                // console.log("正则匹配该视频并获取相应路径==>",matchUrl, captureUrl);
                resMoveurl = captureUrl.replace(
                  "../uploads",
                  that.$api.baseUrl + "/uploads"
                );
              }
            );
            let newMoveStr = matchVideo.replace(
              /<s?video[\s\S]*?>[\s\S]*?<\/video>/gi,
              `<video controls="controls" style="max-width:80%; margin: 4px;"><source src="${resMoveurl}" type="video/mp4"/></video>`
            );
            newStr = newStr.replace(matchVideo, newMoveStr);
          }
        );
      }
      return newStr;
    },
    // 大写数字自动编号
    AutoNumberByIndex(vNumber) {
      var vLetter = [
        "一",
        "二",
        "三",
        "四",
        "五",
        "六",
        "七",
        "八",
        "九",
        "十",
      ];
      if (vNumber < 10) {
        return vLetter[vNumber];
      }
    },
    // 字母自动编号
    AutoNumberByLetter(vNumber) {
      var vLetter = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
      if (vNumber < 26) {
        return vLetter[vNumber];
      } else {
        var vInt = parseInt((vNumber + 1) / 26);
        var vRemainder = (vNumber + 1) % 26;
        if (vRemainder == 0) {
          vInt = vInt - 1;
          vRemainder = 26;
        }
        return vLetter[vInt - 1] + vLetter[vRemainder - 1];
      }
    },
    //附件在线查看
    appendix(url) {
      window.open(
        this.filePreview + encodeURIComponent(Base64.encode(url)),
        "_bank"
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.main-contain {
  margin-top: 14px;
  // margin-left: 10px;
  // margin-right: 10px;
  min-height: 400px;
  width: 100%;
  position: relative;
  padding-bottom: 60px;

  .contain {
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .rediobt {
      margin-left: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 20px;
      padding: 6px 0px;

      .btitem {
        margin-right: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;

        .circle1 {
          width: 33px;
          height: 33px;
          background: #ffffff;
          box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          .circle0 {
            width: 17px;
            height: 17px;
            background: #8a8a8a;
            border-radius: 50%;
          }

          .bt-color {
            background: #2fbd56;
          }
        }

        .bt-title {
          margin-left: 6px;
          font-size: 18px;

          font-weight: bold;
          color: #8a8a8a;
          user-select: none;
          white-space: nowrap;
        }

        .bt-color {
          color: #2fbd56;
        }
      }
    }

    .Table {
      width: 100%;

      .item-left {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;

        .elimage {
          margin-bottom: 6px;
        }

        .text {
          margin-left: 10px;

          .title {
            font-size: 15px;
            font-weight: 400;
            color: #222222;
            white-space: nowrap;
          }

          .time-contain {
            margin-top: 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .time {
              margin-right: 4px;
              font-size: 15px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #666666;
            }

            .bt {
              margin-left: 4px;
              width: 53px;
              height: 27px;
              line-height: 27px;
              text-align: center;
              background: #d9fae2;
              border: 1px solid #2fbd56;
              border-radius: 4px;
              font-size: 15px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #2fbd56;
              user-select: none;
              cursor: pointer;
            }
          }
        }
      }

      .item-middle {
        .title {
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #222222;
          text-align: center;
        }

        .num {
          margin-top: 10px;
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #666666;
          text-align: center;
        }

        .look {
          margin-top: 10px;
          font-size: 15px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #3d84ff;
          user-select: none;
          cursor: pointer;
          text-align: center;
        }
      }

      .item-right {
        display: flex;
        justify-content: center;
        align-items: flex-end;

        .bt {
          width: 72px;
          height: 26px;
          line-height: 26px;
          text-align: center;
          background: #3d84ff;
          border-radius: 4px;
          font-size: 15px;
          font-weight: 400;
          color: #ffffff;
          user-select: none;
          cursor: pointer;
        }
      }
    }
  }
}

.my_test {
  display: block;
  max-width: 100%;
  padding: 5px 10px;

  .remark {
    font-size: 16px;
    padding: 10px;
    box-shadow: 0px 0px 2px 0px #00000050;
  }

  .order {
    min-width: 20px;
    margin-right: 5px;
  }

  .topic_type {
    font-size: 16px;
    height: 50px;
    display: flex;
    align-items: center;

    .classify {
      font-weight: 700;
      margin-right: 10px;
    }
  }

  .subject {
    display: block;
    max-width: 100%;
    padding: 5px 10px;

    .question {
      font-size: 14px;
      min-height: 40px;
      display: flex;

      // align-items: baseline;
      .score {
        min-width: 40px;
      }

      .title {
        ::v-deep p {
          padding: 0;
          margin: 0;
          margin-bottom: 5px;
        }
      }
    }

    .option {
      font-size: 14px;
      min-height: 30px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;

      .qs_right {
        width: 30px;
        height: 30px;
        min-width: 30px;
        text-align: center;
        line-height: 30px;
        border-radius: 50%;
        background: #ffffff;
        border: 1px solid #d8d8d8;
      }

      .qs_active {
        color: #ffffff;
        border-color: #2a7cec;
        background: #2a7cec;
        opacity: 0.9;
      }

      .content {
        ::v-deep p {
          padding: 0;
          margin: 0;
          margin-bottom: 5px;
        }
      }
    }

    .user_answer {
      max-width: 100%;
      padding: 10px;
      box-sizing: border-box;
      box-shadow: 0px 0px 5px 0px #00000050;
      display: flex;
      align-items: flex-start;

      .remark {
        font-size: 14px;
        padding: 0px;
        box-shadow: none;
      }

      .imgAnalysis {
        width: 30px;
        min-width: 30px;
        height: 30px;
        min-height: 30px;
        margin: 5px;
        margin-right: 10px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .user_section {
        max-width: 100%;
        display: flex;
        flex-wrap: wrap;

        .section_title {
          min-width: 80px;
        }
      }

      .answer_item {
        ::v-deep p {
          padding: 0;
          margin: 0;
          margin-bottom: 5px;
        }
      }

      .answer {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
      }

      .file_list {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;

        .file {
          padding: 3px 5px;
          cursor: pointer;
        }
      }

      .rightness {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
      }
    }
  }
}

.customdialog {
  position: relative;
  .dialogbody {
    max-height: 60vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
::v-deep .custom-dialog .customdialog .dialog-footer {
  height: 10px;
}
</style>
